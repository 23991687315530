import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import './Product.css';

function ProductDetails(props) {
  const { product, asin } = props;

  const [timeLeft, setTimeLeft] = useState(null);

  const dealHasNotEnded =
    product.deals && product.deals.ends_at * 1000 > Date.now();

  useEffect(() => {
    let timer;
    if (product.deals && product.deals.ends_at * 1000 > Date.now()) {
      const updateTimer = () => {
        const currentTime = Date.now();
        const endTime = product.deals.ends_at * 1000;
        const timeRemaining = endTime - currentTime;
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
        } else {
          setTimeLeft(0);
          clearInterval(timer);
        }
      };
      updateTimer();
      timer = setInterval(updateTimer, 1000);
      return () => clearInterval(timer);
    }
  }, [product.deals]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      '0'
    );
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const associateID = 'findfast0a-20'; // Replace with your actual Amazon Associates ID

  const constructAmazonLink = (asin) => {
    return `http://www.amazon.com/dp/${asin}/ref=nosim?tag=${associateID}`;
  };

  const findVariantLink = () => {
    if (!asin || asin === product.asin) {
      return [constructAmazonLink(product.asin), product.main_image.link, null];
    }

    for (const variant of product.variants) {
      if (variant.asin === asin) {
        const imageUrl =
          variant.image_url && variant.image_url.trim() !== ''
            ? variant.image_url
            : product.main_image.link;
        return [constructAmazonLink(variant.asin), imageUrl, variant];
      }
    }

    return [constructAmazonLink(product.asin), product.main_image.link, null];
  };

  const [variantLink, imageLink, variant] = findVariantLink();

  const isVariantSelected = variant !== null; // Determine if a variant is selected

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1;
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage,
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  const getColor = (value) => {
    switch (value) {
      case 'Positive':
        return 'green';
      case 'Mixed':
        return 'orange';
      case 'Negative':
        return 'red';
      default:
        return 'black';
    }
  };

  const skip_rows = [
    'Department',
    'ASIN',
    'Best Sellers Rank',
    'size',
    'color',
    'Country of Origin',
  ];

  const columnStyle = { width: '50%' };

  return (
    <Card
      className="custom-card"
      style={{ maxWidth: '500px', margin: '0 auto' }}
    >
      <a href={variantLink} target="_blank" rel="noopener noreferrer">
        <img
          src={imageLink}
          className="card-img-top"
          alt={product.name}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectFit: 'scale-down',
          }}
        />
      </a>
      <Card.Body>
        <a href={variantLink} target="_blank" rel="noopener noreferrer">
          <Card.Title
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              height: '96px',
            }}
          >
            {product.title}
          </Card.Title>
        </a>
        <Rating rating={product.rating} numReviews={product.ratings_total} />
        {isVariantSelected ? (
          // Show price for the selected variant
          <Card.Text>
            ${Number(0.8 * product.price).toFixed(2)} - $
            {Number(1.8 * product.price).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              Price depends on size/color combo
            </span>
          </Card.Text>
        ) : product.deals && dealHasNotEnded ? (
          <Card.Text>
            <span style={{ color: 'red' }}>
              -{Number(product.deals_saving_percentage)}%
            </span>{' '}
            ${Number(product.price).toFixed(2)}
            {timeLeft !== null && (
              <div
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  fontSize: 'smaller',
                  display: 'inline-block',
                  padding: '2px 4px',
                  marginLeft: '5px',
                }}
              >
                Ends in {formatTimeLeft(timeLeft)}
              </div>
            )}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.deals ? (
          // Deal has ended
          <Card.Text>
            ${Number(product.deals.list_price_value).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : product.price ? (
          // No deals
          <Card.Text>
            ${Number(product.price).toFixed(2)}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}

        {/* Customer feedback table */}
        {product.customers_say && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Attribute</th>
                <th style={columnStyle}>Customer Opinion</th>
              </tr>
            </thead>
            <tbody>
              {product.customers_say.map((feedback, index) => (
                <tr key={index}>
                  <td style={columnStyle}>{feedback.name}</td>
                  <td
                    style={{ color: getColor(feedback.value), ...columnStyle }}
                  >
                    {feedback.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* Specifications table */}
        {product.specifications_wo_map && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Specification</th>
                <th style={columnStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={columnStyle}>Brand</td>
                <td style={columnStyle}>{product.brand}</td>
              </tr>
              {product.specifications_wo_map
                .filter((spec) => !skip_rows.includes(spec.name))
                .map((spec, index) => (
                  <tr key={index}>
                    <td style={columnStyle}>{spec.name}</td>
                    <td style={columnStyle}>{spec.value}</td>
                  </tr>
                ))}
              <tr>
                <td style={columnStyle}>Date first available</td>
                <td style={columnStyle}>
                  {new Date(
                    product.date_first_available * 1000
                  ).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProductDetails;
